  <template>
  <v-card
    class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
    :max-width="800"
    outlined
  >
    <CardToolbar
      :back-button-route="LOAD_DATA_ROUTE"
      :back-button-tooltip="'Load Data'"
      button-mode
      include-back
      :text-color="titleColor"
      :title="titleText"
    />

    <!-- When an error occurs during file validation/parsing. -->
    <v-card-text class="mt-n4">
      <v-alert
        v-model="alert"
        :icon="ALERT_ICON"
        outlined
        type="error"
        text
      >
        Errors were found in the provided file, see below for details.
      </v-alert>

      <v-alert
        v-model="alert"
        :icon="WRENCH_ICON"
        outlined
        type="success"
        text
      >
        <v-row align="center">
          <v-col class="grow">Correct these errors before uploading the file.</v-col>
          <v-col class="shrink">
            <v-btn
              @click="$emit('reset')"
              color="success"
              v-text="'Ready to Upload'"
            />
          </v-col>
        </v-row>
      </v-alert>
    
      <v-expansion-panels class="mb-0">
        <v-expansion-panel
          v-for="(error, i) in errors"
          :key="i"
        >
          <v-expansion-panel-header disable-icon-rotate>
            {{ error.message }}
            <template v-slot:actions>
              <span
                class="mt-1 font-weight-bold error--text"
                v-text="'data' in error ? error.data.length : 1"
              />
              <v-icon right color="error">{{ ALERT_ICON }}</v-icon>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <template v-if="error.message === 'File rows contain invalid data.'">
              <!-- When there are errors involving rows in the file, display them as a simple table. -->
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-right">Row</th>
                      <th>Column</th>
                      <th>Value</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, k) in error.data"
                      :key="k"
                    >
                      <td class="text-right">{{ item.row }}</td>
                      <td>{{ item.column }}</td>
                      <td>{{ item.value }}</td>
                      <td>{{ item.message }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            <template v-else>
              <!-- Otherwise, display them as an ordered list. -->
              <v-list class="mt-0">
                <v-list-item-group v-if="'data' in error">
                  <v-list-item
                    v-for="(invalidData, j) in error.data"
                    :key="j"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="`${j + 1}. ${invalidData}`" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item v-else>
                  <v-list-item-content>
                    <p class="body-2 pa-0 ma-0" v-text="error.resolution" />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-card-text class="py-0">
      <v-alert
        v-model="alert"
        :icon="WRENCH_ICON"
        outlined
        type="success"
        text
      >
        <v-row align="center">
          <v-col class="grow">Correct these errors before uploading the file.</v-col>
          <v-col class="shrink">
            <v-btn
              @click="$emit('reset')"
              color="success"
              v-text="'Ready to Upload'"
            />
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { ICONS, ROUTES, COLOR, } from '../../constants'

export default {
  components: {
    CardToolbar: () => import('../layout/toolbars/CardToolbar.vue'),
  },
  props: {
    errors: {
      type: Object,
      default: null,
    },
    titleColor: {
      type: String,
      default: COLOR.ERROR,
    },
    titleText: {
      type: String,
      default: 'Default Title',
    }
  },
  data: () => ({
    alert: true,
  }),
  methods: {
    initTemplateConstants() {
      this.ALERT_ICON = ICONS.ALERT
      this.WRENCH_ICON = ICONS.WRENCH
      
      this.LOAD_DATA_ROUTE = ROUTES.LOAD_DATA
    }
  },
  created () {
    this.initTemplateConstants()
  }
}
</script>